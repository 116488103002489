#popup {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($dark-grey, 0);
    z-index: 99999999999999999999999;
    max-width: 1400px;
    padding: 20px;
    //align-items: center
    opacity: 0;
    top: -100%;
    display: flex;
    transition: background 400ms ease 100ms;
    .content {
        overflow: auto;
        top: -900px;
        transition: all 300ms ease;
        background: white;
        padding: 40px 0;
        margin: 0 auto;
        height: 100%;
        //max-width: 900px
        box-shadow: 0 2px 6px rgba(black, 0.2);
        border-top: 4px solid $orange;
        position: relative; }
    &.active {
        top: 0;
        opacity: 1;
        background: rgba($dark-grey, 0.95);


        .content {
            top: 0;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            @media (max-height: 1000px) {
                display: block; }
            h2 {
                margin: 10% auto 30px;
                line-height: 1.1;
                font-size: 1.6em;
                color: $orange;
                max-width: 700px;
                padding: 0 20px; }
            p {
                max-width: 700px;
                color: $dark-grey;
                line-height: 1.6;
                font-size: 1em;
                margin: 0 auto;
                padding: 0 20px; }
            #close {
                position: absolute;
                right: 20px;
                top: 10px;
                font-size: 2em;
                display: block;
                cursor: pointer; }
            #input {
                padding: 2rem;
                text-align: center;
                h3 {
                    font-family: 'Playfair Display', serif;
                    color: $dark-grey;
                    font-size: 1.4rem;
                    font-weight: 100; }
                .modal__brand-logo {
                    width: 100%;
                    max-width: 300px;
                    margin-bottom: 2rem; }


                .modal__image-large {
                    width: 80%;
                    margin: 0 auto 2rem;
                    display: block;
                    max-width: 700px; }
                .modal__image-thumbs {
                    display: flex;
                    align-items: center;
                    //flex-wrap: wrap
                    justify-content: center;
                    .modal__image-thumb {
                        width: 40%;
                        max-width: 150px;
                        margin: 1rem;
                        cursor: pointer;
                        border: 1px solid $medium-grey;
                        padding: 2rem;
                        transition: all 200ms ease;
                        &:hover, &.active {
                            box-shadow: 0 0 10px rgba(black, 0.15);
                            border: 1px solid $light-grey; }
                        &.active {
                            border: 1px solid $medium-grey; } } } } } } }
