.container {
    max-width: 1200px;
    margin: 0 auto; }

.row {
    padding: 30px;
    display: block; }
.soft-row {
    display: flex;
    flex-wrap: wrap; }
.col-1 {
    flex: 1 0 0; }
.col-2 {
    flex: 2 0 0; }
.col-3 {
    flex: 3 0 0; }
.col-4 {
    flex: 4 0 0; }
.col-5 {
    flex: 5 0 0; }
.col-6 {
    flex: 6 0 0; }
body, html {
    max-width: 1400px;
    margin: 0 auto;
    height: 900px;
    overflow: auto; }

body {
    border: 1px solid $grey; }

.clearfix {
    clear: both; }

.color {
    color: $orange; }
.bgcolor {
    background: $orange;
    fill: $medium-grey; }
.color-fill {
    fill: $orange; }
.headline {
    text-align: left;
    p {
        color: $dark-grey;
        font-size: 0.925rem; } }
button {
    border: none;
    position: relative;
    cursor: pointer;
    background: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    .button {
        display: inline-block;
        height: 45px;
        padding: 0 20px;
        line-height: 45px;
        &:hover {
            box-shadow: none; } }
    .button-span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $light-grey;
        top: 0;
        width: 50px;
        height: 45px;
        //padding: 0.5rem
        line-height: 45px;
        img {
            width: 2rem; } } }

