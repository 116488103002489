.brandHeader {
    display: flex;
    height: 25vw;
    max-height: 300px;
    min-height: 200px;
    .col-1 {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 400px;
            width: 80%;
            &.liujo {
                max-width: 250px; }
            &.lacoste {
                max-width: 300px; } } }
    .keyvisual {
        flex: 1 0 0;

        &.longchampDetailKeyvisual {
            background: url(../img/longchamp/keyvisual.jpg) center no-repeat;
            background-size: cover;
            background-position: center!important;
            position: relative;
            // &:after
            //     content: ""
            //     position: absolute
            //     right: 0px
            //     bottom: 10px
            //     width: 70px
            //     height: 20px
            //     background: url(../img/longchamp/jenner.svg) no-repeat left bottom
 }            //  background-size: 60px
        &.liujoDetailKeyvisual {
            background: url(../img/liujo/keyvisual.jpg) top 40% center no-repeat;
            background-size: cover; }
        &.nikeDetailKeyvisual {
            background: url(../img/nike/keyvisual.jpg) center no-repeat;
            background-size: cover; }
        &.lacosteDetailKeyvisual {
            background: url(../img/lacoste/keyvisual.jpg) center no-repeat;
            background-size: cover; }
        &.calvinkleinDetailKeyvisual {
            background: url(../img/calvinklein/keyvisual.jpg) center no-repeat;
            background-size: cover; } } }

.brandThumbs {
    width: 100%;
    flex-wrap: no-wrap!important;
    margin: 0;
    padding: 0;
    height: auto;
    margin-bottom: -5px;
    @media (max-width: 650px) {
        display: none; }
    .brandThumbImage {
        flex: 1 0 0;
        margin: 0;
        padding: 0;
        img {
            max-width: 100%;
            object-fit: cover; } }
    .brandThumbVideo {
        flex: 2 0 0;
        margin: 0;
        padding: 0;
        position: relative;
        #pause {
            opacity: 0;
            visibility: hidden;
            transition: all 300ms ease; }
        &:hover {
            #pause {
                opacity: 1;
                visibility: visible; } }
        &.small {
            flex: 1 0 0; }
        video {
            max-width: 100%;
            object-fit: cover; } } }

.back {
    background: $light-grey;
    padding: 40px 0 0 40px;
    padding-bottom: 2rem; }

.brandInfo {
    background: $light-grey;

    .soft-row {
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .col-1 {
            padding: 0 40px 40px;
            min-width: 50%;
            p {
                margin-top: 0; }
            .grid-row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(2, 180px);
                grid-template-areas: "S1 L" "S2 L";
                .s1 {
                    grid-area: S1;
                    background: url(../img/longchamp/LO_gallery_s1.jpg) top center;
                    background-size: cover;
                    padding: 10px; }
                .s2 {
                    grid-area: S2;
                    background: url(../img/longchamp/LO_gallery_s2.jpg) center;
                    background-size: cover;
                    padding: 10px; }
                .l {
                    grid-area: L;
                    background: url(../img/longchamp/LO_galleryL.jpg) top right;
                    background-size: cover;
                    padding: 10px; } } }
        @media (max-width: 700px) {
            display: block;
            .col-1 {
                padding: 0 40px;
                &:first-child {
                    padding-top: 20px; } } } }




    p {
        margin-top: 30px;
        color: $dark-grey;
        font-size: 1.1rem;
        line-height: 1.6;

        .desktop {
            font-size: inherit;
            @media (max-width: 650px) {
                display: none; } } }

    img {
        text-align: right;
        width: 300px;
        max-width: 100%;
        min-width: 250px;
        margin-right: -40px; }

    .carousel {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        max-width: 500px;
        margin: 40px auto;
        position: relative;
        padding: 0px;
        min-width: 300px;
        .hero-carousel {
            width: 100%;
            margin: 0 auto;
            position: relative;

            .carousel-item {
                background: #fff;
                left: 0;
                padding: 0px;
                text-align: center;
                box-shadow: 0 0 20px rgba(black, 0.05);
                position: relative;
                transition: all 300ms ease;
                opacity: 0;
                position: relative;
                display: none;
                &:after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 22px);
                    height: calc(100% - 22px);
                    border: 2px solid $grey;
                    z-index: 99;
                    left: 10px;
                    top: 10px; }
                b {
                    font-size: 1.2rem; }
                img {
                    display: block;
                    margin: 0 auto 30px;
                    max-width: 100%;
                    min-width: 20px; }
                &.active {
                    left: 0;
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    padding: 60px;
                    opacity: 1; } } }

        .prev, .next {
            background: $dark-grey;
            text-align: center;
            display: inline-block;
            width: 35px;
            height: 35px;
            min-width: 35px;
            min-height: 35px;
            position: absolute;
            cursor: pointer;
            transform: translateY(-50%);
            transition: all 200ms ease;
            top: 50%;
            z-index: 99;
            span {
                color: white;
                font-size: 2.2rem;
                line-height: 30px; }
            &:hover {
                background: $orange; } }
        .prev {
            left: -10px; }
        .next {
            right: -10px; } }
    @media (max-width: 530px) {
        .carousel {
            display: block;
            padding: 0;
            text-align: center;
            margin: 0;
            .prev, .next {
                position: relative;
                top: 0;
                transform: none;
                margin: 10px; } } } }


#mute {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 20px;
    cursor: pointer;
    z-index: 20; }
#pause {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 30px;
    z-index: 20;
    pointer-events: none; }
