@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
        overflow-x: hidden; }
    #app {
        display: flex;
        flex-wrap: wrap;
        .brille {
            &.active {
                width: 20%;
                display: block;
                @media (max-width: 960px) {
                    width: 25%; }
                @media (max-width: 681px) {
                    width: 50%; } } }
        .brandbanner {
            width: 100%;
            .col-1 {
                height: 100%;
                img {
                    width: auto!important; } } } }
    #filter {
        .filterItem {
            @media (max-width: 870px) {
                display: block; }
            .selectWrapper {
                padding-right: 0;
                width: auto;

                select {
                    width: 100%;
                    padding: 5px 5px 5px 10px; }
                &:after {
                    content: none; } } } } }
@supports not (display: grid) {
    #app {
        display: flex;
        flex-wrap: wrap;
        .brille {
            &.active {
                width: 20%;
                display: block;
                @media (max-width: 960px) {
                    width: 25%; }
                @media (max-width: 681px) {
                    width: 50%; } } }
        .brandbanner {
            width: 100%;
            .col-1 {
                height: 100%;
                img {
                    width: auto!important; } } } }
    #filter {
        .filterItem {
            @media (max-width: 870px) {
                display: block; }
            .selectWrapper {
                padding-right: 0;
                width: auto;

                select {
                    width: 100%;
                    padding: 5px 5px 5px 10px; }
                &:after {
                    content: none; } } } } }
