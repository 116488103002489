
@import url('https://fonts.googleapis.com/css?family=Arimo:400,400i,700');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400i,700i');

* {
	font-family: Arimo, sans-serif;
	font-size: 13px; }

a {
	color: $dark-grey;
	&:hover {
		color: $orange; } }

h1, h2 {
	font-size: 3em;
	margin-bottom: 30px;
	text-transform: uppercase; }
h3 {
	font-size: 2.4em;
	margin-bottom: 30px; }
h4 {
	font-size: 1.8em;
	margin-bottom: 30px; }
h5 {
	font-size: 1.4em;
	margin-bottom: 30px; }
em {
	font-family: 'Playfair Display', serif;
	font-size: 1.1rem;
	color: $dark-grey; }



