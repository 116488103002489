.title {
    padding: 25px 40px 20px;
    background: #fff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999999999;
    width: 100%;
    max-width: 1398px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 870px) {
        position: relative;
        height: auto; }
    @media (max-width: 680px) {
        padding: 15px 10px; }

    h1 {
        font-size: 1.6rem;
        letter-spacing: 0.2rem;
        color: $dark-grey;
        text-align: left;
        line-height: 1.2;
        min-width: 200px;
        display: inline-block;
        margin: 0;
        @media (max-width: 340px) {
            font-size: 1.2rem;
            min-width: 50px; } }
    .header--text {
        display: flex;
        align-items: center;
        color: $dark-grey;
        justify-content: center;
        min-width: 100%;
        padding-top: 10px;
        border-top: 1px solid $grey;
        p {
            display: block; }
        img {
            margin-left: 10px; } }
    @media (max-width: 650px) {
        //justify-content: center
        .header--text {
            border-top: 1px solid $medium-grey;
            padding-top: 10px;
            min-width: 100%;
            order: 3;
            margin-top: 10px; } }
    #info {
        text-align: center;
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-end;
        font-weight: 600;
        //padding-left: 7px
        //margin-left: 7px
        min-width: 100px;
        //border-left: 1px solid $orange
        line-height: 0;
        @media (max-width: 340px) {
            min-width: 50px; }
        span {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: $orange;
            color: white;
            border-radius: 50%;
            line-height: 15px;
            margin-left: 7px;
            position: relative;
            font-weight: bold; } } }


#filter {
    max-width: 1398px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    z-index: 9999999999;
    // height: 50px
    background: $grey;
    padding: 10px 10px 0;
    border-bottom: 2px solid $medium-grey;
    text-align: center;
    position: fixed;
    top: 70px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media (max-width: 870px) {
        position: relative;
        height: auto;
        top: 0; }
    .filterItem {
        margin-bottom: 10px;
        #favoriten-mailen {
            display: block;
            cursor: pointer;
            svg {
                width: 2rem; } }
        b {
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            color: $dark-grey; }
        .selectWrapper {
            position: relative;
            width: 180px;
            margin: 0 20px 0 5px;
            display: inline-block;
            &:after {
                content: "▼";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                color: $orange; }

            select {
                appearance: none;
                border: none;
                outline: none;
                border-radius: 0;
                padding: 5px 30px 5px 10px;
                color: $dark-grey;
                width: 100%; } } }
    @media (max-width: 870px) {
        flex-direction: column;
        .filterItem {
            display: flex;
            justify-content: center;
            b {
                text-align: left;
                width: 60px;
                margin-right: 10px; }

            .selectWrapper {
                margin: 0;
                max-width: 80%;
                flex: 1 0 0;
                background: #fff; } } } }

#app {
    margin-top: 120px;
    background: $light-grey;
    display: grid;
    grid-gap: 20px;
    padding: 40px;
    grid-template-columns: repeat(4, 1fr);
    // grid-auto-flow: row
    // grid-auto-rows: minmax(auto, 180px)
    // min-height: 100%
    #noResult {
        grid-column: 1/-1;
        text-align: center;
        font-size: 1.3em;
        letter-spacing: 0.1em;
        color: $dark-grey;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); }

    .brille {
        background: white;
        border: 1px solid $grey;
        padding: 20px;
        align-items: center;
        justify-content: center;
        display: none;
        position: relative;
        transition: all 100ms ease;
        cursor: pointer;
        max-height: 182px;
        .kidz {
            position: absolute;
            left: 1rem;
            top: 1rem;
            opacity: 0.5;
            width: 35px; }
        .heart {
            width: 1.5rem;
            margin-right: 0.5rem; }
        .heart-empty {
            opacity: 0.5; }
        .heart-full {
            display: none;
            &.favourite {
                display: block;
                fill: $orange; } }

        &.active {
            display: flex;
            flex-direction: column; }
        svg {
            width: 35px;
            height: 35px;
            position: absolute;
            right: 0px;
            top: 0px;
            //background: $grey
            padding: 3px; }
        .brillenImages {
            width: 100%;
            min-height: 100px;
            margin: 20px auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center; }
        .model--nummer {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 0.825em; }
        .model--online {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px; }
        .model--logo {
            position: absolute;
            display: block;
            bottom: 10px;
            left: 10px;
            max-width: 70px;
            max-height: 20px;
            &.longchamp {
                max-width: 100px;
                max-height: 30px; }
            &.nike {
                max-width: 50px; }
            &.lacoste, &.calvinklein {
                max-width: 100px; }
            &.liujo {
                max-width: 50px; } }
        img {
            max-width: 100%;
            transition: all 200ms ease;
            opacity: 1;
            &.brilleFace {
                width: 100%; }
            &.brilleProfile {
                width: 100%;
                display: none; } }
        .overlay {
            position: absolute;
            border: none;
            outline: none;
            pointer-events: none;
            padding: 20px 10px 10px;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 1.3;
            background: rgba(#333, 0.9);
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 500ms ease;
            &:before {
                content: "";
                width: calc(100% - 12px);
                height: calc(100% - 12px);
                left: 5px;
                top: 5px;
                border: 1px solid white;
                position: absolute; }
            span {
                transition: all 500ms ease;
                font-size: 1.2em;
                position: relative;
                top: 20px;
                font-family: 'Playfair Display', serif; }
            em {
                top: 30px;
                margin: 10px 0 5px;
                font-size: 0.825em;
                padding: 10px 0 0;
                position: relative;
                transition: all 300ms ease;
                max-width: 100%;
                display: block;
                &:before {
                    transition: all 400ms ease;
                    content: "";
                    width: 0;
                    height: 1px;
                    background: rgba(white, 0.3);
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%); }


                input {
                    all: unset;
                    appearance: none;
                    overflow: visible;
                    min-width: 150px;
                    padding: 0;
                    background: transparent;
                    margin: 0;
                    display: inline-block;
                    font-size: inherit;
                    font-style: normal;
                    font-family: Arimo, sans-serif;
                    text-align: center;
                    color: $grey;
                    position: relative; } }
            @media (max-width: 450px) {
                span {
                    font-size: 1em; }
                em {
                    font-size: 0.825em;

                    input {
                        font-size: inherit; } } } }
        &:hover {
            border-color: white;
            box-shadow: 2px 2px 8px rgba(black, 0.15);
            .overlay {
                opacity: 1;
                span, em {
                    top: 0;
                    &:before {
                        width: 100px;
                        height: 1px; } } }


            img {
                &.brilleProfile {
                    display: block; }
                &.brilleFace {
                    display: none; } } } }


    .brandbanner {
        grid-column: 1 / -1;
        box-shadow: 0 1px 6px rgba(black, 0.1);
        background: white;
        display: flex;
        flex-wrap: wrap;
        height: 40vw;
        max-height: 329px;
        min-height: 200px;
        display: none;
        &.active {
            display: flex; }

        .col-1 {
            flex: 1 0 0;
            padding: 20px;
            text-align: center;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 300px;
            &.liujo {
                background-position: top 38% center!important; }
            &.longchamp {
                background-position: center!important;
                position: relative;
                // &:after
                //     content: ""
                //     position: absolute
                //     right: 0
                //     bottom: 10px
                //     width: 70px
                //     height: 20px
                //     background: url(../img/longchamp/jenner.svg) no-repeat left bottom
 }                //     background-size: 60px
            &.calvinklein, &.lacoste {
                background-position: center center!important; }
            &.nike {
                background-position: center!important; }
            .brandLogo {
                width: 100%;
                max-width: 400px;
                margin: 0 auto 30px;
                display: block;
                &.nike {
                    max-width: 190px;
                    margin-bottom: 40px; }
                &.liujo {
                    max-width: 200px; }
                &.lacoste {
                    max-width: 300px; }
                &.calvinklein {
                    max-width: 250px;
                    margin-bottom: 40px; } } }
        @media (max-width: 681px) {
            max-height: none;
            height: auto;
            .col-1 {
                order: 1;
                padding-bottom: 80%;
                &.brand {
                    order: 2;
                    padding: 40px; } } } }
    @media (max-width: 960px) {
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: minmax(auto, 180px); }


    @media (max-width: 681px) {
        padding: 10px;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(auto, auto); }
    @media (max-width: 870px) {
        margin-top: 0; }
    @media (max-width: 340px) {
        display: block;
        .brille {
            margin-bottom: 10px; } } }


.button {
    display: inline-block;
    padding: 15px 35px;
    color: white;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.875rem;
    transition: all 200ms ease;
    box-shadow: 0px 0px 10px rgba(black, 0.0);
    margin: 0 auto;
    &:hover {
        color: white;
        opacity: 0.8;
        box-shadow: 1px 1px 10px rgba(black, 0.2); } }
.brandImage {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%,-50%); }


#mail-form {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    input {
        padding: 0.5rem 1rem;
        margin: 2rem auto;
        width: 100%; } }
